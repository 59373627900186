var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flex-column",
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("base-search", {
            staticStyle: { display: "inline-block" },
            attrs: {
              searchList: _vm.$cbglDscjSearch,
              listQuery: _vm.listQuery,
              dynamic: true,
              noOperation: true,
              hasOtherFormItem: true,
            },
            on: { search: _vm.handleFilter, "btn-event": _vm.onBtnClicked },
          }),
          _c(
            "el-button",
            {
              staticStyle: { position: "absolute", top: "5px", right: "20px" },
              attrs: { type: "success", size: "mini" },
              on: { click: _vm.onUpgrade },
            },
            [_vm._v("升级")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "bg-white",
          staticStyle: { height: "100%" },
          attrs: { id: "table_box_height" },
        },
        [
          _c("basic-table", {
            key: _vm.nowtimer,
            attrs: {
              tableHeight: _vm.tableHeight,
              tableHeaderList: _vm.firstHeaderList,
              tableData: _vm.tableData,
              listQuery: _vm.listQuery,
              tableTotal: _vm.tableTotal,
              hasSelection: true,
              isCbxx: true,
            },
            on: {
              pagination: _vm.handleCurrentChange,
              tableRowClick: _vm.tableRowClick,
              handleSelectionChange: _vm.handleSelectionChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-mini",
          staticStyle: { "text-align": "center" },
          attrs: {
            width: "400px",
            title: "上传附件",
            visible: _vm.dialogFormVisible,
          },
          on: {
            close: _vm.closeUpload,
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                "label-width": "80px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { size: "mini", label: "版本号", prop: "Version" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入版本号", maxlength: 8 },
                    model: {
                      value: _vm.formData.Version,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "Version", $$v)
                      },
                      expression: "formData.Version",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { size: "mini", label: "校验码", prop: "HashCode" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入校验码" },
                    model: {
                      value: _vm.formData.HashCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "HashCode", $$v)
                      },
                      expression: "formData.HashCode",
                    },
                  }),
                ],
                1
              ),
              _c("p", { staticStyle: { "font-size": "12px", color: "red" } }, [
                _c("span", { staticStyle: { color: "red" } }),
                _vm._v("Tips:只支持哈希hash256校验码"),
              ]),
            ],
            1
          ),
          _c(
            "el-upload",
            {
              ref: "uploadDom",
              attrs: {
                drag: "",
                "file-list": _vm.fileList,
                data: _vm.params,
                limit: 1,
                name: "Files",
                "on-success": _vm.handleUploadSuccess,
                "before-upload": _vm.handleBeforeUpload,
                "on-error": _vm.uperror,
                headers: _vm.tokenHeader,
                action: _vm.baseURL + "/HSDevices/SendDeviceUpdateCmd",
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
            ]
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.closeUpload } },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "升级",
            visible: _vm.dialogVisible,
            width: "30%",
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("el-progress", { attrs: { percentage: _vm.percentage } }),
          _vm.isDone
            ? _c("p", [_vm._v("升级完成")])
            : _c("p", [
                _vm._v(
                  "正在请求升级，大约剩余 " + _vm._s(_vm.remainder) + " s"
                ),
              ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelUpgrade } }, [
                _vm._v("取消升级"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.loadingVisible
        ? _c(
            "div",
            { staticClass: "loading-overlay" },
            [
              _c("el-progress", {
                attrs: {
                  type: "circle",
                  percentage: _vm.loadingPercentage,
                  "stroke-width": 20,
                },
              }),
              _c("p", { staticClass: "tips_css" }, [
                _vm._v("升级中，请稍后！"),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }