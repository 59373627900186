<template>
  <div class="flex-column" v-loading="loading">
    <!-- {{ filesize }} -->
    <div class="filter-container">
      <base-search :searchList="$cbglDscjSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;" :dynamic='true'  :noOperation="true" :hasOtherFormItem="true" ></base-search>
      <el-button type="success" size="mini" @click="onUpgrade" style="position: absolute; top: 5px; right: 20px;">升级</el-button>
    </div>
    <div class="bg-white" style="height: 100%;" id="table_box_height">
      <!-- 列表页 -->
      <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" :hasSelection="true" @handleSelectionChange="handleSelectionChange" :isCbxx="true"  :key="nowtimer"></basic-table >
    </div>
    <!-- 上传文件 -->
    <el-dialog class="dialog-mini" width="400px" @close="closeUpload" style="text-align:center" :title="'上传附件'" :visible.sync="dialogFormVisible">
      <el-form ref="form" :model="formData" label-width="80px" :rules="rules">
        <el-form-item size="mini" :label="'版本号'" prop="Version"   >
            <el-input v-model="formData.Version"   placeholder="请输入版本号" :maxlength="8"></el-input>
        </el-form-item>
        <el-form-item size="mini" :label="'校验码'" prop="HashCode"   >
            <el-input v-model="formData.HashCode"   placeholder="请输入校验码"></el-input>
        </el-form-item>
        <p style="font-size: 12px;color: red;"><span style="color: red;"></span>Tips:只支持哈希hash256校验码</p>
      </el-form>
      <el-upload ref="uploadDom" drag :file-list="fileList" :data="params"  :limit="1" name="Files"  :on-success="handleUploadSuccess"  :before-upload="handleBeforeUpload" :on-error="uperror" :headers="tokenHeader" :action="baseURL +'/HSDevices/SendDeviceUpdateCmd'"  >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <div slot="footer" >
        <el-button size="mini" @click="closeUpload">关闭</el-button>
      </div>
    </el-dialog>


    <el-dialog title="升级" :visible.sync="dialogVisible" width="30%" :show-close="false">
      <el-progress :percentage="percentage"></el-progress>
      <p v-if="isDone">升级完成</p>
      <p v-else>正在请求升级，大约剩余 {{ remainder }} s</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelUpgrade">取消升级</el-button>
      </span>
    </el-dialog>

    <div class="loading-overlay" v-if="loadingVisible">
      <el-progress type="circle" :percentage="loadingPercentage" :stroke-width="20" ></el-progress>
      <p class="tips_css">升级中，请稍后！</p>
    </div>
  </div>
</template>
<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import { getToken } from '@/utils/auth'
export default {
  name: "sbsj", //设备升级
  components: {},
  props: {
    //网关id
    GatewayId: [String, Object, Array],
    //是否是详情，详情进入不可修改
    isCheckDetail: {
      default: false,
    },
  },
  data() {
    return {
      timeoutId:null,
      timeId:null,
      filesize:0,
      rules:{
        Version:[{  required:true,message:'版本号不能为空',trigger:'blur'  }],
        HashCode:[{  required:true,message:'校验码不能为空',trigger:'blur'  }],
      },
      formData:{
        Version:"",
	      HashCode:"",
      },
      fileList:[],
      params:{
        DeviceNo: JSON.parse(this.$route.query.row).deviceNo,
        belongApp:"附件",
        belongAppId:"ENCLOSURE"
      },
      tokenHeader: {'X-Token': getToken(),Tenantid:JSON.parse(localStorage.getItem("userCode"))},
      baseURL: process.env.VUE_APP_BASE_API, // api的base_url
      dialogFormVisible:false,
      loading:false,//遮罩
      listQuery: { // 查询条件
        page: 1,
        limit: 50,
        TreeIds:[],//选中集合
        queryType: '0',
        queryKey:'UserNumber',
        queryValue:'',
        OtherKey:'',//查询条件
        OtherValue:'',//查询内容
        issueType:'0',//操作范围
        ids:[],//操作范围传值
        timer:'',
        beginTime:'',
        endTime:'',
        repeat:'',
      },
      firstHeaderList:[// 主列表列定义
        {
          key :  "name",
          comment :  "升级名称",
          description :  "升级名称",
        },
        {
          key :  "version",
          comment :  "当前版本",
          description :  "当前版本",
        },
        {
          key :  "creatTime",
          comment :  "创建时间",
          description :  "创建时间",
        },
      ],
      tableTotal:0,//明细条数
      chooseList:[],//多选数据
      tableData:[],
      tableHeight:500,//表格高度
      dialogVisible: false, //弹窗
      percentage: 0, //进度百分比
      loadingPercentage: 0,//进度百分比
      loadingVisible: false,//开启遮罩
      isDone: true, //是否升级完成
      remainder: 0, //剩余时间
      nowtimer:1,
    };
  },
  computed: {
    
  },
  mounted() {
    this.getList()
    var allHeight = window.innerHeight
    this.tableHeight = allHeight - 45 - 10 - 10 - 40- 100 - 52
    // 计算table高度
    this.$nextTick(()=>{
      var allHeight = window.innerHeight
      this.tableHeight = allHeight - 45 - 10 - 10 - 40- 100 - 52
      // let ele=document.getElementById("table_box_height");
      // this.tableHeight=ele.offsetHeight + 70
      this.nowtimer = new Date().toString()
      this.$forceUpdate()
      window.addEventListener('resize', () => { 
        var allHeight = window.innerHeight
        this.tableHeight = allHeight - 45 - 10 - 10 - 40- 100 - 52
        // let ele=document.getElementById("table_box_height");
        // this.tableHeight=ele.offsetHeight + 70
        this.nowtimer = new Date().toString()
        this.$forceUpdate()
      });
      // 计算table高度
    })
  },
  beforeDestroy() {
    clearInterval(this.timeId);
    this.timeId =null
  },
  methods: {
    // 上传之前的校验
    handleBeforeUpload(file){
      this.$refs.form.validate((valid) => {
          if (valid) {
            this.filesize = file.size
            this.params = Object.assign( this.params, this.formData)
            return true
          } else {
            return false
          }
        });
    },
    // 上传失败
    uperror(){
      this.$message.error("上传失败")
      this.fileList =[]
    },
    // 升级保存配置
    sbxqRecordDeviceUpdate(subData){
      basicDataApi.sbxqRecordDeviceUpdate(subData).then((res)=>{
        if(res.code ==200){
          this.$message.success("升级成功")
        }else{
          this.$message.error("升级失败")
        }
        this.getList()
      })
    },
    // 上传成功
    handleUploadSuccess(res, file) {
      // console.log(res, 78999)
        if(res.code==500){
          this.$message.error("上传失败")
          this.$refs.uploadDom.clearFiles()
          return
        }
        if(res.code ==200&&res.result.executeState==1){
          this.loadingPercentage =0
          this.loadingVisible=true
          this.dialogFormVisible =false
          clearTimeout(this.timeoutId)
          this.timeoutId=setTimeout(() => {
            if(this.timeId){
              clearInterval(this.timeId);
              this.timeId =null
              this.loadingVisible = false;
              this.$message.error("升级失败")
              this.getList()
            }
          }, 65000);
          // 
            setTimeout(() => {
              this.timeId = setInterval(() => {
                basicDataApi.sbxqQueryDeviceUpdateProgress({deviceNo: JSON.parse(this.$route.query.row).deviceNo}).then((res)=>{
                if(res.code ==200&&res.result.executeState==1){
                  this.dialogFormVisible =false
                  this.loadingVisible = true;
                  if(Number(JSON.parse(res.result.executeResult).flag2) ==100){
                    this.loadingPercentage =Number(JSON.parse(res.result.executeResult).flag2)
                    setTimeout(() => {
                      clearInterval(this.timeId);
                      this.timeId =null
                      this.loadingVisible = false;
                      this.sbxqRecordDeviceUpdate({deviceNo: JSON.parse(this.$route.query.row).deviceNo,Version:this.formData.Version})
                    }, 200);
                  }else{
                    this.loadingPercentage =Number(JSON.parse(res.result.executeResult).flag2)
                  }
                }else{
                  clearInterval(this.timeId);
                  this.timeId =null
                  this.loadingVisible = false;
                  this.$message.error("升级失败")
                  this.getList()
                }
              }).catch(()=>{
                  clearInterval(this.timeId);
                  this.timeId =null
                  this.loadingVisible = false;
                  this.$message.error("升级失败")
                  this.getList()
              })
            }, 2000);
          }, 100);
          this.fileList =[]
        }else{
          this.$message.error("上传失败")
          this.$refs.uploadDom.clearFiles()
          return
        }
      },
    // 取消
    closeUpload(){
      this.dialogFormVisible =false
    },
    // 设备升级
    onUpgrade(){
      this.dialogFormVisible =true
     this.$nextTick(()=>{
      this.$refs.uploadDom.clearFiles()
     })
    },
    // 获取数据
    getList() {
      this.listQuery.DeviceId =  JSON.parse(this.$route.query.row).id
      this.loading = true
      basicDataApi.cpxqHSDeviceUpdateRecordsLoad(this.listQuery).then(response => {
        if(response.code == 200){
          response.columnHeaders.forEach((item) => { // 首字母小写
            item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
          })
          this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
          this.tableData = response.data
          this.tableTotal = response.count
        }else{
          this.firstHeaderList = []
          this.tableData = []
          this.$notify({
            message: response.message,
            type: 'warning',
            duration: 2000
          });
        }
        this.loading = false
      }).catch(()=>{
        this.firstHeaderList = []
        this.tableData = []
        this.loading = false
      })
    },
    // 搜索
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    // 切换分页
    handleCurrentChange(val) {
      this.tableChooseRow = {}//重置选中项
      this.listQuery.page = val.page
      this.listQuery.limit = val.limit
      this.getList()
    },
    // 行点击
    tableRowClick(row){
      this.tableChooseRow = row
    },
    //多选
    handleSelectionChange(val){
      this.chooseList = val
    },
    handleDelete(row) { // 多行删除
      this.tableData.splice(this.tableData.findIndex(e=>e.serialNumber === row.serialNumber),1)
    },
    // 按钮点击
    onBtnClicked: function(domId) {
      console.log('you click:' + domId)
      if(domId == 'btnAdd'){//新增
        this.formData={
          Version:"",
          HashCode:"",
        }
        this.FormDialog = true
        return
      }
      if(domId == 'btnEdit'){//编辑
        if(this.chooseList.length != 0){
          this.$message.error('请选择一个设备进行操作！');
          return;
        }
        this.FormDialog = true
        return
      }
      if(domId == 'btnDel'){//删除
        if(this.chooseList.length <= 0){
          this.$message.error('请选择需要删除的设备！');
          return;
        }
        this.$message.success('删除成功');
        return
      }
      
      if(domId == 'btnExport'){//导出
        this.handleDownExcel()
        return
      }
    },
    //取消升级
    cancelUpgrade(){

    },
  }
};
</script>
<style lang="scss" scoped >
.loading-overlay {
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
  ::v-deep .el-progress__text{
    font-size: 24px !important;
    color: rgb(30, 160, 225) !important;
  }
  ::v-deep .el-progress-circle{
    width: 150px !important;
    height: 150px !important;
  }
  .tips_css{
    font-size: 24px;
    color: rgb(30, 160, 225)
  }
</style>
